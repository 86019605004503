import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Modal, Paper } from '@mui/material';
import { useEffect } from 'react';
import colors from 'src/assets/sass/colors';
import config from 'src/config';

type SupportRequestFormProps = {
  isOpen: boolean;
  onClose: () => void;
  transitAgencyName: string;
};

const BROWSER_LANG = navigator.language.substring(0, 2);

const SupportRequestForm = ({ isOpen, onClose, transitAgencyName }: SupportRequestFormProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: config.hubspotPortalId,
          formId: BROWSER_LANG === 'en' ? config.hubspotFormEN : config.hubspotFormFR,
          target: '#hubspot-form',
          onFormReady: ($form: any) => {
            if (transitAgencyName) {
              const hiddenInput = document.createElement('input');
              hiddenInput.type = 'hidden';
              hiddenInput.name = 'transit_agency';
              hiddenInput.value = transitAgencyName;

              // Append the hidden input field to the form
              const formElement = $form[0];
              formElement.appendChild(hiddenInput);
            }
          }
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose} style={styles.modal}>
      <Paper style={styles.modalContent}>
        <Box display="flex" justifyContent="end">
          <span onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} color={colors.blaiseGray} size="lg" />
          </span>
        </Box>
        <div id="hubspot-form"></div>
      </Paper>
    </Modal>
  );
};

export default SupportRequestForm;

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContent: {
    padding: '32px',
    overflow: 'auto',
    height: '80%',
    width: '60%',
    borderRadius: '4px'
  }
};
