import { createTheme, adaptV4Theme } from '@mui/material/styles';
import colors from './colors';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: colors.blaiseGreen, contrastText: colors.buttonGreyText },
      secondary: { main: colors.red, contrastText: colors.white }
    },
    typography: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 14
    },

    overrides: {
      MuiButton: {
        // Name of the styleSheet
        root: {
          // Name of the rule
          textTransform: 'none',
          fontSize: '16px',
          padding: '4px 16px',
          textAlign: 'center',
          height: '36px'
        }
      },
      MuiDrawer: {
        // Navbar background color
        paper: { background: colors.blaiseLightGreen }
      },
      MuiSnackbarContent: {
        root: {
          // Snackbar font styling
          color: colors.white,
          fontSize: 16
        }
      },
      MuiPaper: {
        rounded: {
          // Border radius of dialogs
          borderRadius: 4,
          padding: 10
        }
      },
      MuiTableCell: {
        stickyHeader: {
          // TableHeader styling
          backgroundColor: colors.blaiseLightGreen,
          color: colors.blaiseGray,
          fontWeight: 'bold',
          fontSize: 14,
          border: 'none'
        },
        root: {
          // TableRow styling
          borderBottomColor: colors.blaiseLightGray,
          borderBottomWidth: 1,
          padding: 15
        }
      },
      MuiTableFooter: {
        // TableFooter styling
        root: {
          backgroundColor: colors.blaiseLightGreen,
          color: colors.blaiseGray,
          fontWeight: 'bold',
          fontSize: 14,
          border: 'none'
        }
      },
      MuiToggleButton: {
        root: {
          backgroundColor: colors.white,
          color: colors.blaiseGray,
          '&.Mui-selected': {
            backgroundColor: colors.blaiseGreen,
            color: colors.black,
            '&:hover': {
              backgroundColor: colors.blaiseGreen
            }
          }
        }
      }
    }
  })
);

export default theme;
