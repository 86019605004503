import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, MenuItem, Button, Typography } from '@mui/material';
import colors from '../../assets/sass/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faBus,
  faBusAlt,
  faMapSigns,
  faUser,
  faUserTie,
  faSignOutAlt,
  faClock,
  faPhone,
  faHistory,
  faUsers,
  faRoute,
  faCommentDots,
  faChartBar,
  faCircle,
  faHome,
  faLock,
  faFunnelDollar,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faCity,
  faBell,
  faCaretDown,
  faCaretUp,
  faBriefcase
  // faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons';
import blaiseLogo from '../../assets/images/blaiseLogo.png';
import './NavBar.scss';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import { currentUserStore } from '../../store/user';
import { analyticsStore } from '../../store/analytics';
import { driverShiftStore } from '../../store/driverShift';
import { calledInRequestStore } from '../../store/calledInRequest';
import { TransitAgencyFeatures } from '../../enums/TransitAgency';
import { transitAgencyStore } from '../../store/transitAgency';
import { charterRequestStore } from 'src/store/charterRequest';
import { useEffect, useRef, useState } from 'react';
import { NavPath, PathPermission } from 'src/enums/MenuNavigation';
import { checkPermissions } from 'src/services/authUtils';

const NavBar = ({ setNavBarWidth }) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { pathname } = location;
  const boxRef = useRef(null);
  const match = useRouteMatch(NavPath.BLAISE_ADMIN_DASHBOARD);
  const path = match ? match.path : null;

  let history = useHistory();
  const [openTabs, setOpenTabs] = useState({ operations: false, fares: false, admin: false });
  const [calculatedItemsContainerHeight, setCalculatedItemsContainerHeight] = useState(0);
  const { transitAgencyDetails } = transitAgencyStore((state) => ({
    transitAgencyDetails: state.transitAgencyDetails
  }));
  const { currentUser, setCurrentUser } = currentUserStore((state) => ({
    currentUser: state.currentUser,
    setCurrentUser: state.setCurrentUser
  }));
  const { analyticsUrl } = analyticsStore((state) => ({
    analyticsUrl: state.analyticsUrl
  }));

  const { driverShiftsAreEndingSoon, driverShiftsForReboot } = driverShiftStore((state) => ({
    driverShiftsAreEndingSoon: state.driverShiftsAreEndingSoon,
    driverShiftsForReboot: state.driverShiftsForReboot
  }));
  const { calledInRequestRequiresAction } = calledInRequestStore((state) => ({
    calledInRequestRequiresAction: state.calledInRequestRequiresAction
  }));
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const { hasFundsFeature, hasChartersFeature, hasOrganizationsFeature, hasInvoiceFeature, funds } =
    transitAgencyStore((state) => ({
      hasFundsFeature: state.getFeature(TransitAgencyFeatures.FARE_ASSISTANCE_FUNDS),
      hasChartersFeature: state.getFeature(TransitAgencyFeatures.CHARTERS),
      hasOrganizationsFeature: state.getFeature(TransitAgencyFeatures.ORGANIZATIONS),
      hasInvoiceFeature: state.getFeature(TransitAgencyFeatures.INVOICES),
      funds: state.funds
    }));

  const { upcomingChartersWithinTwoWeeks } = charterRequestStore((state) => ({
    upcomingChartersWithinTwoWeeks: state.upcomingChartersWithinTwoWeeks
  }));

  const showNotificationForFunds = funds?.find((fund) => fund.showWarning);

  // nav bar width is dynamic
  // app needs updated value to calculate left margin for pages
  useEffect(() => {
    const boxRefCurrentWidth = boxRef?.current?.offsetWidth;
    const boxRefCurrentHeight = boxRef?.current.offsetHeight;
    const navbarItemsContainerHeight = // Scales depending on screen size.
      boxRefCurrentHeight > 800
        ? boxRefCurrentHeight * 0.7
        : boxRefCurrentHeight > 550
        ? boxRefCurrentHeight * 0.65
        : boxRefCurrentHeight * 0.6;

    setCalculatedItemsContainerHeight(navbarItemsContainerHeight);

    if (!boxRefCurrentWidth) return;

    const resizeObserver = new ResizeObserver(() => {
      const width = boxRefCurrentWidth;

      if (width) {
        setNavBarWidth(width);
      }
    }).observe(boxRef.current);

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  const hasLastMinuteShiftChangePermission = checkPermissions(
    user,
    'execute:lastMinuteShiftChange'
  );
  const displayAlertForDriverShiftReboot =
    hasLastMinuteShiftChangePermission && driverShiftsForReboot?.length;

  const assignClassName = (path) => {
    return pathname === path ? 'selectedNavbarItem' : '';
  };

  const checkEmployeeManagementPermissions = () =>
    checkPermissions(user, PathPermission.READ_AGENTS);
  const checkFleetManagementPermissions = () => checkPermissions(user, PathPermission.FLEETS);
  const checkVehicleManagementPermissions = () => checkPermissions(user, PathPermission.VEHICLES);
  const checkBusStopManagementPermissions = () => checkPermissions(user, PathPermission.NETWORK);
  const checkDriverManagementPermissions = () => checkPermissions(user, PathPermission.DRIVERS);
  const checkDriverShiftManagementPermissions = () =>
    checkPermissions(user, PathPermission.DRIVER_SHIFTS);
  const checkCalledInManagementPermissions = () =>
    checkPermissions(user, PathPermission.CALLED_IN_REQUEST);
  const checkPassengerManagementPermissions = () =>
    checkPermissions(user, PathPermission.PASSENGERS);
  const checkSystemParametersManagementPermissions = () =>
    checkPermissions(user, PathPermission.SETTINGS);
  const checkMyProfilePermissions = () => checkPermissions(user, PathPermission.PROFILE);
  const checkBlaiseAdminDashboardPermissions = () =>
    checkPermissions(user, PathPermission.READ_SUPER_ADMIN);
  const checkHistoryManagementPermissions = () => checkPermissions(user, PathPermission.TRIPS);
  const checkRouteManagmentPermissions = () => checkPermissions(user, PathPermission.ROUTES);
  const checkFundsManagmentPermissions = () =>
    checkPermissions(user, PathPermission.FUNDS) && hasFundsFeature;
  const checkInvoicesManagmentPermissions = () =>
    checkPermissions(user, PathPermission.INVOICES) && hasInvoiceFeature;
  const checkOrganizationManagementPermissions = () =>
    checkPermissions(user, PathPermission.ORGANIZATIONS) && hasOrganizationsFeature;
  const checkPushNotificationsManagementPermissions = () =>
    checkPermissions(user, PathPermission.PUSH_NOTIFICATIONS);

  const displayUpcomingWarning = upcomingChartersWithinTwoWeeks.some(
    (charter) => charter.displayNotice
  );

  const handleTabClick = (tab) => {
    const currentTabState = openTabs[tab];

    setOpenTabs((prevState) => ({
      ...prevState,
      [tab]: !currentTabState
    }));
  };

  const displayFareTab = hasOrganizationsFeature || hasFundsFeature || hasInvoiceFeature;
  return (
    <Drawer variant="permanent" anchor="left">
      <img src={blaiseLogo} className="logoNavbar" alt="logo" />
      <Box
        ref={boxRef}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className="navBox"
      >
        <Box style={{ height: calculatedItemsContainerHeight }} className="navbar-items-container">
          <Box>
            <MenuItem
              className={assignClassName(NavPath.HOME)}
              button
              onClick={() => history.push(NavPath.HOME)}
              selected={pathname === NavPath.HOME}
            >
              <FontAwesomeIcon icon={faHome} fixedWidth />
              &ensp; {t('home')}
            </MenuItem>

            {checkCalledInManagementPermissions() && (
              <MenuItem
                className={assignClassName(NavPath.CALLED_IN_REQUEST)}
                button
                onClick={() => history.push(NavPath.CALLED_IN_REQUEST)}
                selected={pathname === NavPath.CALLED_IN_REQUEST}
              >
                <FontAwesomeIcon icon={faPhone} fixedWidth />
                &ensp; {t('called_in_requests')}
                {calledInRequestRequiresAction || displayUpcomingWarning ? (
                  <FontAwesomeIcon
                    style={{
                      paddingLeft: '8px',
                      width: '8px'
                    }}
                    icon={faCircle}
                    color={colors.red}
                    size="xs"
                  />
                ) : null}
              </MenuItem>
            )}
            {checkHistoryManagementPermissions() && (
              <MenuItem
                className={assignClassName(NavPath.TRIPS)}
                button
                onClick={() => history.push(NavPath.TRIPS)}
                selected={pathname === NavPath.TRIPS}
              >
                <FontAwesomeIcon icon={faHistory} fixedWidth />
                &ensp; {t('trips_management_title')}
              </MenuItem>
            )}
            {checkHistoryManagementPermissions() && hasChartersFeature && (
              <MenuItem
                className={assignClassName(NavPath.CHARTERS)}
                button
                onClick={() => history.push(NavPath.CHARTERS)}
                selected={pathname === NavPath.CHARTERS}
              >
                <FontAwesomeIcon icon={faHistory} fixedWidth />
                &ensp; {t('charters')}
              </MenuItem>
            )}
            {checkRouteManagmentPermissions() && (
              <MenuItem
                className={assignClassName(NavPath.ROUTES)}
                button
                onClick={() => history.push(NavPath.ROUTES)}
                selected={pathname === NavPath.ROUTES}
              >
                <FontAwesomeIcon icon={faRoute} fixedWidth />
                &ensp; {t('route_management_title')}
              </MenuItem>
            )}
            {/* <MenuItem
            className={assignClassName('/realtime')}
            button
            onClick={() => history.push('/realtime')}
            selected={pathname === '/realtime'}
          >
            <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth />
            &ensp; Interactive map
          </MenuItem> */}
            {checkPassengerManagementPermissions() && (
              <MenuItem
                className={assignClassName(NavPath.PASSENGERS)}
                button
                onClick={() => history.push(NavPath.PASSENGERS)}
                selected={pathname === NavPath.PASSENGERS}
              >
                <FontAwesomeIcon icon={faUsers} fixedWidth />
                &ensp; {t('passenger_management')}
              </MenuItem>
            )}

            {/* Tabs under operations category */}
            <MenuItem
              button
              onClick={() => handleTabClick('operations')}
              selected={openTabs.operations}
            >
              <div className="tabMenuItem">
                <span>
                  <FontAwesomeIcon icon={faBus} fixedWidth />
                  &ensp; {t('operations')}
                  {driverShiftsAreEndingSoon || displayAlertForDriverShiftReboot ? (
                    <FontAwesomeIcon
                      style={{
                        paddingLeft: '8px',
                        width: '8px'
                      }}
                      icon={faCircle}
                      color={colors.red}
                      size="xs"
                    />
                  ) : null}
                </span>
                <FontAwesomeIcon icon={openTabs.operations ? faCaretUp : faCaretDown} />
              </div>
            </MenuItem>

            {openTabs.operations ? (
              <div className="tabSubMenuItem">
                {checkPushNotificationsManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.PUSH_NOTIFICATIONS)}
                    button
                    onClick={() => history.push(NavPath.PUSH_NOTIFICATIONS)}
                    selected={pathname === NavPath.PUSH_NOTIFICATIONS}
                  >
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                    &ensp; {t('push_notifications')}
                  </MenuItem>
                )}
                {checkFleetManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.FLEETS)}
                    button
                    onClick={() => history.push(NavPath.FLEETS)}
                    selected={pathname === NavPath.FLEETS}
                  >
                    <FontAwesomeIcon icon={faBus} fixedWidth />
                    &ensp; {t('fleet_management_title')}
                  </MenuItem>
                )}
                {checkVehicleManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.VEHICLES)}
                    button
                    onClick={() => history.push(NavPath.VEHICLES)}
                    selected={pathname === NavPath.VEHICLES}
                  >
                    <FontAwesomeIcon icon={faBusAlt} fixedWidth />
                    &ensp; {t('vehicle_management_title')}
                  </MenuItem>
                )}
                {checkBusStopManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.NETWORK)}
                    button
                    onClick={() => history.push(NavPath.NETWORK)}
                    selected={pathname === NavPath.NETWORK}
                  >
                    <FontAwesomeIcon icon={faMapSigns} fixedWidth />
                    &ensp; {t('network_title')}
                  </MenuItem>
                )}
                {checkDriverManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.DRIVERS)}
                    button
                    onClick={() => history.push(NavPath.DRIVERS)}
                    selected={pathname === NavPath.DRIVERS}
                  >
                    <FontAwesomeIcon icon={faUserTie} fixedWidth />
                    &ensp; {t('driver_management_title')}
                  </MenuItem>
                )}
                {checkDriverShiftManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.DRIVER_SHIFTS)}
                    button
                    onClick={() => history.push(NavPath.DRIVER_SHIFTS)}
                    selected={pathname === NavPath.DRIVER_SHIFTS}
                  >
                    <span style={{ paddingRight: '10px' }}>
                      <FontAwesomeIcon icon={faClock} fixedWidth />
                      &ensp; {t('driver_shifts_title')}
                      {driverShiftsAreEndingSoon || displayAlertForDriverShiftReboot ? (
                        <FontAwesomeIcon
                          style={{
                            paddingLeft: '8px',
                            width: '8px'
                          }}
                          icon={faCircle}
                          color={colors.red}
                          size="xs"
                        />
                      ) : null}
                    </span>
                  </MenuItem>
                )}
              </div>
            ) : null}

            {/* Tabs under fare management category */}
            {displayFareTab ? (
              <MenuItem button onClick={() => handleTabClick('fares')} selected={openTabs.fares}>
                <div className="tabMenuItem">
                  <div>
                    <FontAwesomeIcon icon={faFunnelDollar} fixedWidth />
                    &ensp; {t('fare_management')}
                  </div>

                  <FontAwesomeIcon icon={openTabs.fares ? faCaretUp : faCaretDown} />
                </div>
              </MenuItem>
            ) : null}

            {displayFareTab && openTabs.fares ? (
              <div className="tabSubMenuItem">
                {checkFundsManagmentPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.FUNDS)}
                    button
                    onClick={() => history.push(NavPath.FUNDS)}
                    selected={pathname === NavPath.FUNDS}
                  >
                    <FontAwesomeIcon icon={faFunnelDollar} fixedWidth />
                    &ensp; {t('funding_programs')}
                    {showNotificationForFunds ? (
                      <FontAwesomeIcon
                        style={{ paddingLeft: '8px' }}
                        icon={faExclamationTriangle}
                        color={colors.red}
                        size="lg"
                      />
                    ) : null}
                  </MenuItem>
                )}
                {checkOrganizationManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.ORGANIZATIONS)}
                    button
                    onClick={() => history.push(NavPath.ORGANIZATIONS)}
                    selected={pathname === NavPath.ORGANIZATIONS}
                  >
                    <FontAwesomeIcon icon={faCity} fixedWidth />
                    &ensp; {t('organizations')}
                  </MenuItem>
                )}
                {checkInvoicesManagmentPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.INVOICES)}
                    button
                    onClick={() => history.push(NavPath.INVOICES)}
                    selected={pathname === NavPath.INVOICES}
                  >
                    <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth />
                    &ensp; {t('invoicing')}
                  </MenuItem>
                )}
              </div>
            ) : null}

            {/* Tabs under admin category */}
            <MenuItem button onClick={() => handleTabClick('admin')} selected={openTabs.admin}>
              <div className="tabMenuItem">
                <div>
                  <FontAwesomeIcon icon={faBriefcase} fixedWidth />
                  &ensp; {t('admin')}
                </div>

                <FontAwesomeIcon icon={openTabs.admin ? faCaretUp : faCaretDown} />
              </div>
            </MenuItem>

            {openTabs.admin ? (
              <div className="tabSubMenuItem">
                {analyticsUrl && Object.keys(analyticsUrl).length ? (
                  <MenuItem
                    className={assignClassName(NavPath.ANALYTICS)}
                    button
                    onClick={() => history.push(NavPath.ANALYTICS)}
                    selected={pathname === NavPath.ANALYTICS}
                  >
                    <FontAwesomeIcon icon={faChartBar} fixedWidth />
                    &ensp; {t('analytics_title')}
                  </MenuItem>
                ) : null}
                {checkSystemParametersManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.SETTINGS)}
                    button
                    onClick={() => history.push(NavPath.SETTINGS)}
                    selected={pathname === NavPath.SETTINGS}
                  >
                    <FontAwesomeIcon icon={faCog} fixedWidth />
                    &ensp; {t('system_parameters_title')}
                  </MenuItem>
                )}
                {checkBlaiseAdminDashboardPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.BLAISE_ADMIN_DASHBOARD)}
                    button
                    onClick={() => history.push(NavPath.BLAISE_ADMIN_DASHBOARD)}
                    selected={path === NavPath.BLAISE_ADMIN_DASHBOARD}
                  >
                    <FontAwesomeIcon icon={faLock} fixedWidth />
                    &ensp; {t('blaise_dashboard')}
                  </MenuItem>
                )}
                {checkEmployeeManagementPermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.EMPLOYEES)}
                    button
                    onClick={() => history.push(NavPath.EMPLOYEES)}
                    selected={pathname === NavPath.EMPLOYEES}
                  >
                    <FontAwesomeIcon icon={faUserTie} fixedWidth />
                    &ensp; {t('employee_management_title')}
                  </MenuItem>
                )}
                {checkMyProfilePermissions() && (
                  <MenuItem
                    className={assignClassName(NavPath.PROFILE)}
                    button
                    onClick={() => history.push(NavPath.PROFILE)}
                    selected={pathname === NavPath.PROFILE}
                  >
                    <FontAwesomeIcon icon={faUser} fixedWidth />
                    &ensp; {t('profile_title')}
                  </MenuItem>
                )}
                {currentUser !== null && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://tally.so/r/mBxggA?app=engine&email=${currentUser.email}&agency=${currentUser.transit_agency_id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem id="feedback" button>
                      <FontAwesomeIcon icon={faCommentDots} fixedWidth />
                      &ensp; {t('give_feedback')}
                    </MenuItem>
                  </a>
                )}
              </div>
            ) : null}
          </Box>
        </Box>
        <Box className="navbar-bottom-container">
          {transitAgencyDetails && (
            <Box>
              <img
                src={
                  transitAgencyDetails.logo_url ?? config.apiServer + transitAgencyDetails.logo_path
                }
                id="agencyLogo"
                alt="transitAgencyLogo"
                style={{ height: '60px', objectFit: 'contain', maxWidth: '120px' }}
              />
            </Box>
          )}
          <Typography variant="caption" style={{ color: colors.blaiseGray }}>
            {config.version || ''}
          </Typography>
          {!isAuthenticated && (
            <Button id="loginButton" variant="outlined" onClick={() => loginWithRedirect()}>
              {t('login')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}

          {isAuthenticated && (
            <Button
              id="logoutButton"
              variant="outlined"
              onClick={() => {
                setCurrentUser(null);
                logout({ returnTo: window.location.origin });
              }}
              style={{ borderWidth: 2 }}
            >
              {t('logout')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavBar;
