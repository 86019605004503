import { Box, Button, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import busPhoto from '../../assets/images/home-page-hero.svg';
import HomeCard from '../../components/HomeCard/HomeCard';
import {
  faExclamationTriangle,
  faBook,
  faNewspaper,
  faQuestionCircle,
  faPhone,
  faHeadset
} from '@fortawesome/free-solid-svg-icons';
import './Home.scss';
import { calledInRequestStore } from '../../store/calledInRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';
import { getHomePageDataFromWebflow } from 'src/api/home';
import SupportRequestForm from 'src/components/SupportRequestForm/SupportRequestForm';
import { transitAgencyStore } from 'src/store/transitAgency';

const BROWSER_LANG = navigator.language.substring(0, 2);

const Home = () => {
  const { t } = useTranslation('common');
  const transitAgency = transitAgencyStore((state) => state.transitAgencyDetails);
  const { numberOfTripsThatRequireAction } = calledInRequestStore();
  const [isLoadingWebflowData, setIsLoadingWebflowData] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState({
    releaseNotesUrl: '',
    releaseVersionNumber: ''
  });
  const [pressReleases, setPressReleases] = useState([]);
  const [openRequestForm, setOpenRequestForm] = useState(false);

  useEffect(() => {
    getDataFromWebflow();
  }, []);

  const seeMore = {
    notifications: {
      internalRoute: '/called-in-request'
    },
    analytics: {
      internalRoute: '/analytics'
    },
    releaseNotes: {
      externalLink:
        'https://blaisetransit.notion.site/e162ac94e62449c392cc73fa62b79bfe?v=bcfc3af3e4474adeac74f9423afbddf0'
    },
    faq: {
      externalLink:
        'https://blaisetransit.notion.site/Blaise-Support-Center-Home-6f3ab1d7a83b40f087639b650d0905b3'
    },
    pressReleases: {
      externalLink: 'https://www.blaisetransit.com/blog'
    },
    contact: {
      externalLink: 'https://www.blaisetransit.com/contact-us'
    }
  };

  const faq = [
    {
      title: t('new_to_blaise'),
      link: 'https://blaisetransit.notion.site/Blaise-Support-Center-Home-6f3ab1d7a83b40f087639b650d0905b3'
    },
    {
      title: t('engine_guidebook'),
      link:
        BROWSER_LANG === 'en'
          ? 'https://www.notion.so/blaisetransit/Blaise-Engine-Guidebook-EN-5d91237737554f48bbb060833599d288'
          : 'https://www.notion.so/blaisetransit/Guide-d-utilisation-Blaise-Engine-FR-01a9cd9d98e444d5824d1ff2e0f8064f'
    },
    {
      title: t('emergency_support'),
      link:
        BROWSER_LANG === 'en'
          ? 'https://www.notion.so/blaisetransit/Emergency-support-protocol-f90992d2f068422d8590df382e982fe3'
          : 'https://www.notion.so/blaisetransit/Protocole-d-assistance-d-urgence-5a1d2d4b50d341a3afad19c2185b6ea1'
    },
    {
      title: t('analytics_dashboard'),
      link: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=227cbdb7-09b2-4627-8894-355a9e7cfe95&ctid=dc535832-1320-4b99-af9b-32819b34c4bd'
    }
  ];

  const getDataFromWebflow = async () => {
    try {
      setIsLoadingWebflowData(true);

      const { releaseNotes, pressReleases } = await getHomePageDataFromWebflow();

      setReleaseNotes(releaseNotes);
      setPressReleases(pressReleases);
    } catch (err) {
      console.log('Home::getDataFromWebflow', err);
    } finally {
      setIsLoadingWebflowData(false);
    }
  };

  return (
    <>
      {openRequestForm && transitAgency?.name ? (
        <SupportRequestForm
          isOpen={openRequestForm}
          onClose={() => setOpenRequestForm(false)}
          transitAgencyName={transitAgency.name}
        />
      ) : null}

      {/* Title */}
      <Box className="pageTitle">{t('home')}</Box>

      {/* Hero image */}
      <Box className="bus-hero-image-container">
        <img src={busPhoto} className="bus-hero-image" alt="logo" />
      </Box>

      {/* Cards  */}
      <Box>
        <Grid container spacing={3} style={{ marginBottom: '12px' }}>
          {/* Notifications */}
          <HomeCard
            icon={faExclamationTriangle}
            title="notifications"
            subtitle={t('notifications_card_subtitle')}
            seeMore={seeMore.notifications}
          >
            <Typography className="larger-text">
              {numberOfTripsThatRequireAction === 0 ? (
                <>{t('no_new_notifications')}</>
              ) : (
                <>
                  {t('new_notification_1')}{' '}
                  <Box component="span" style={{ fontWeight: 'bold' }}>
                    {numberOfTripsThatRequireAction}
                  </Box>{' '}
                  {t('new_notification_2', {
                    plural: numberOfTripsThatRequireAction > 1 ? 's' : ''
                  })}
                </>
              )}
            </Typography>
          </HomeCard>

          {/* Release notes */}
          <HomeCard icon={faBook} title="release_notes" seeMore={seeMore.releaseNotes}>
            {isLoadingWebflowData ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography className="larger-text" style={{ textAlign: 'center' }}>
                  {t('most_recent_patch')}{' '}
                  <Box component="span" style={{ fontWeight: 'bold' }}>
                    {releaseNotes.releaseVersionNumber}
                  </Box>
                  .
                </Typography>
                <Typography className="larger-text" style={{ textAlign: 'center' }}>
                  {t('view_patch_notes')}{' '}
                  <Link
                    onClick={() => window.open(releaseNotes.releaseNotesUrl, '_blank')}
                    style={{ cursor: 'pointer', color: colors.black }}
                  >
                    {t('here')}
                  </Link>
                </Typography>
              </>
            )}
          </HomeCard>

          {/* FAQ */}
          <HomeCard icon={faQuestionCircle} title="faq" seeMore={seeMore.faq}>
            <Box className="centered-container">
              {faq.map((item) => (
                <Link
                  onClick={() => window.open(item.link, '_blank')}
                  style={{ cursor: 'pointer', color: 'black' }}
                  key={item.title}
                >
                  <Typography className="larger-text">{item.title}</Typography>
                </Link>
              ))}
            </Box>
          </HomeCard>
        </Grid>

        <Grid container spacing={3} style={{ justifyContent: 'center', paddingTop: '12px' }}>
          {/* Press releases */}
          <HomeCard icon={faNewspaper} title="press_releases" seeMore={seeMore.pressReleases}>
            {isLoadingWebflowData ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box className="centered-container">
                {pressReleases.map((article) => (
                  <Link
                    onClick={() => window.open(article.articleUrl, '_blank')}
                    style={{ cursor: 'pointer', color: 'black' }}
                    key={article.articleTitle}
                  >
                    <Typography className="larger-text">{article.articleTitle}</Typography>
                  </Link>
                ))}
              </Box>
            )}
          </HomeCard>

          {/* Contact */}
          <HomeCard icon={faHeadset} title="blaise_support" seeMore={seeMore.contact}>
            <Box display="flex" justifyContent="center" style={{ gap: '32px' }}>
              <Box className="contact-item">
                <Typography className="larger-text">{t('support_by_form')}</Typography>
                <Button
                  variant="contained"
                  onClick={() => setOpenRequestForm(true)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t('support_request')}
                </Button>
              </Box>
              <Box className="contact-item">
                <Typography className="larger-text">{t('call_emergency_support_line')}:</Typography>
                <Link href="tel:+14502860350" style={{ color: colors.black }}>
                  <FontAwesomeIcon icon={faPhone} color={colors.blaiseGreen} size="1x" /> 1
                  450-286-0350
                </Link>
              </Box>
            </Box>
          </HomeCard>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
