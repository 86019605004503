import authenticatedAxiosInstance from 'src/axios/axios-authorized';
import { BusRow } from 'src/models/Bus';

export const getBuses = async (): Promise<BusRow[]> => {
  const response = await authenticatedAxiosInstance.axios.get('/buses?searchType=1');

  return response.data;
};

export const editBus = async (
  busId: number,
  reqBody: {
    bus_fleet_id: number;
    busName: string | null;
  }
): Promise<string> => {
  return (await authenticatedAxiosInstance.axios.put(`/bus/${busId}`, reqBody)).data;
};

export const getBusCode = async (busId: number): Promise<number> => {
  const response = await authenticatedAxiosInstance.axios.get(`/createCode/${busId}`);

  return response.data;
};

export const deregisterBus = async (busId: number): Promise<string> => {
  return (await authenticatedAxiosInstance.axios.put(`/deregisterTabletOnEngine/${busId}`)).data;
};

export const createNewBus = async (
  fleetId: number,
  reqBody: {
    bus_name: string;
  }
): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.post(`/busfleets/${fleetId}/bus`, reqBody)).data
    ?.message;
};

export const deleteBus = async (busId: number): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.delete(`/bus/${busId}`)).data?.message;
};

export const getExtraDetailsForVehicle = async (vehicleId: number) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/vehicle/${vehicleId}/extra-details`
  );

  return response?.data;
};

type UpdateExtraDetailsForVehicleReqBody = {
  vehicleDetails: {
    bus_id: number;
    transit_agency_id: number;
  };
  vehicleExtraDetails: {
    transit_agency_vehicle_extra_detail_id: number;
    transit_agency_vehicle_extra_detail_option_id: number | null;
    value: string | null;
  }[];
};

export const updateExtraDetailsForVehicle = async ({
  vehicleId,
  reqBody
}: {
  vehicleId: number;
  reqBody: UpdateExtraDetailsForVehicleReqBody;
}) => {
  const response = await authenticatedAxiosInstance.axios.put(
    `/vehicle/${vehicleId}/extra-details`,
    reqBody
  );

  return response?.data;
};
